import {api} from "./api";
import * as moment from "moment";


export const accountingApi = {
  transferFunds: async (payment) => {
    return api.accountingSvcRequest('POST', '/fundTransfers', payment);
  },

  xrates: async() => {
    return api.courseSvcRequest("GET", `/exchangeRates?base=IDR&symbols=PHP,ZAR,GHS,USD,SGD,EUR,GBP`);
  },
  
  getUnrealizedSales: async (userIds, beforeMillis) => {
    const pendingUsers = [...userIds];
    let unrealizedSales = {};
    while (pendingUsers.length > 0) {
      const usersToRequest = pendingUsers.splice(0, 50);  
      unrealizedSales = {...await api.accounting2SvcRequest('GET', `/unrealizedSales?before=${moment(beforeMillis).toISOString()}&userIds=${usersToRequest.join(',')}`), ...unrealizedSales};
    }
    return unrealizedSales;
  }
};

const sleep = async (ms) => new Promise((resolve, reject) =>  setTimeout(resolve, ms));
