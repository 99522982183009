import React from "react";
import {userApi} from "../api/userApi";
import { FacebookLoginButton } from "react-social-login-buttons";
import style from "./login.scss";
import history from '../history/history';
import {Link} from "react-router-dom";


export class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submitClicked = this.submitClicked.bind(this);
    this.emailChanged = this.emailChanged.bind(this);
    this.pwdChanged = this.pwdChanged.bind(this);
  }

  render() {
    return (
      <div className={style.login}>
        <div>Create your ninja account</div>
        <input type="text"
                   value={this.state.email}
                   onChange={this.emailChanged}
                   label="E-mail"/>
        <input type="password"
                   value={this.state.password}
                   onChange={this.pwdChanged}
                   label="Password"/>
        <div onClick={this.submitClicked}>submit</div>
        <div>Already got an account? <Link to="/login/email">Log in here</Link></div>
      </div>
    );
  }

  emailChanged(evt) {
    this.setState({email: evt.target.value});
  }

  pwdChanged(evt) {
    this.setState({password: evt.target.value});
  }

  async submitClicked() {
    const creds = {email: this.state.email, password: this.state.password};
    await userApi.createUser(creds);
    await userApi.login(creds);
    history.push('/');
  }
}