import {api} from './api';

export const whatsappApi = {
  refreshQrCode: async () => {
    return api.whatsappSvcRequest('POST', `/qrCodes`);
  },

  getCurrentQrCode: async () => {
    return api.whatsappSvcRequest('GET', `/qrCodes/current`);
  },

  getScreenshot: async () =>  {
    return api.whatsappSvcRequest('GET', `/screenshots/current`);
  }
};
