import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {observer} from "mobx-react";
import {usersStore} from "../store/usersStore";
import {coursesStore} from "../store/coursesStore";
import {courseApi} from "../api/courseApi";
import style from './purchaseHistory.scss';
import moment from "moment";
import {paymentApi} from "../api/paymentApi";
import {logError} from "../errors/errorConsole";

export const PurchaseHistory = observer(
  function PurchaseHistory() {
    const {courseId, studentId: stId} = useParams();
    const [purchases, setPurchases] = useState([]);

    useEffect(() => {
      loadPurchases();
    }, [])

    async function loadPurchases() {
      setPurchases((await courseApi.getPurchasesForCourse(courseId)).filter(p =>
        (p.studentIds || [p.userId]).includes(stId)
      ).sort((p1, p2) => p1.creationTime - p2.creationTime));
    }

    return (
      <div>
        Purchase history for student {usersStore.getUserById(stId)?.name} in
        course {coursesStore.coursesById[courseId]?.name}

        {purchases.map(p => <Purchase purchase={p}/>)}
      </div>
    );
  }
);

function Purchase({purchase: p}) {
  const pkgDescription = getPackageDescription(p);
  return (
    <div className={style.purchase}>
      <div className={style.date}>{moment(p.creationTime).tz("Asia/Jakarta").format("DD-MM-YYYY  HH:mm z")}</div>
      <div className={style.numHours}>{p.numHours} hours <span>({pkgDescription})</span></div>
      <div className={style.price}>
        {p.currency} {p.price} 
        {p.invoiceXRate && 
          <span className={style.person} 
                onClick={() => navigateToInvoice(p.paymentRequestId)}> 
            &nbsp; S${(p.price / p.invoiceXRate).toLocaleString("en", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
          </span>
        }
      </div>
      <div>Paid by: <span className={style.person}>{usersStore.getUserById(p.userId)?.name}</span></div>
      <div>Students: {(p.studentIds || [p.userId]).map(stId => <div className={style.person}>· {usersStore.getUserById(stId)?.name}</div>)}</div>
      {p.price !==0 && <>
        <div>Paid to company: <div className={style.person}>{(p.paidToCompany === "SG" ? "Cerah Education Pte Ltd" : (p.paidToCompany === "ID" ? "PT Cerah Edukasi Bangsa" : "unknown"))}</div></div>
      </>}
    </div>
  );
}

function getPackageDescription(purchase) {
  const {pkgId, notes, price, numHours} = purchase;
  if (pkgId) {
    if (pkgId === "FREE_TRIAL") {
      return "free trial";
    }
    if (pkgId === "FREE_TRIAL_REVERSAL") {
      return "automated free trial reversal";
    }
    if (pkgId === "transfer") {
      return `${numHours > 0 ? "from " : "to "} ${getTransferCourseName(notes)}`;
    }
    if (price > 0) {
      return "paid pkg";
    }
    if (price === 0 && numHours === 1) {
      return "free trial?"
    }
    return "?";
  }
  return "reimbursement?";
}

function getTransferCourseName(purchaseNotes) {
  const pieces = purchaseNotes.split(" ");
  const courseId = pieces[pieces.length - 1];
  if (courseId)
    return coursesStore.coursesById[courseId]?.name;
  return courseId;
}

async function navigateToInvoice(pymtReqId) {
  if (!pymtReqId) return;
  try {
    const pymtReq = await paymentApi.getPaymentRequest(pymtReqId);
    window.open(`https://invoicing.xero.com/view/${pymtReq.invoiceId}`, "_blank");
  } catch (e) {
    logError("Failed to find invoice for this purchase", e)
  }
  
}