import React from "react";
import {Router, Route, Redirect} from "react-router-dom";
import history from "../history/history";
import {Teachers} from "../teachers/teachers";
import {Signup} from "../login/signup";
import {EmailLogin} from "../login/emailLogin";
import {TeacherEditor} from "../teachers/teacherEditor";
import {Courses} from "../courses/courses";
import {CourseEditor} from "../courses/courseEditor";
import {PackageEditor} from "../courses/packageEditor";
import {PurchaseEditor} from "../purchases/purchaseEditor";
import {UserEditor} from "../users/userEditor";
import {CourseMeetings} from "../courses/courseMeetings";
import {UserPicker} from "../users/userPicker";
import {MeetingRescheduler} from "../courses/meetingRescheduler";
import {PayslipEditor} from "../payslips/payslipEditor";
import {Dashboard} from "../dashboard/dashboard";
import {EnrolmentsEditor} from "../enrolments/enrolmentsEditor";
import {WhatsappQrCode} from "../whatsapp/whatsappQrCode";
import {CourseNpsComments} from "../nps/courseNpsComments";
import {TeacherNotification} from "../teachers/teacherNotification";
import {ClassRequests} from "../classRequests/classRequests";
import {ClassRequestEditor} from "../classRequests/classRequestEditor";
import {ClassRequestWithdrawer} from "../classRequests/classRequestWithdrawer";
import {CourseProposalEditor} from "../courseProposals/courseProposalEditor";
import {CourseProposals} from "../courseProposals/courseProposals";
import {Payslips} from "../payslips/payslips";
import {PayslipDisplay} from "../payslips/payslipDisplay";
import {TeacherPayslips} from "../payslips/teacherPayslips";
import {Renewals} from "../renewals/courseRenewals";
import {CourseRenewalWithdrawer} from "../renewals/courseRenewalWithdrawer";
import {TeacherApplications} from "../teacherApplications/teacherApplications";
import {TeacherApplicationScheduleEditor} from "../teacherApplications/teacherApplicationScheduleEditor";
import {Calendar} from "../calendar/calendar";
import {TeacherSchedule} from "../calendar/teacherSchedule";
import {TestCallEditor} from "../courseProposals/testCallEditor";
import {PaymentRequestEditor} from "../purchases/paymentRequestEditor";
import {ReimbursementEditor} from "../purchases/reimbursementEditor";
import {ErrorConsole} from "../errors/errorConsole";
import {TestCallSchedule} from "../calendar/testCallSchedule";
import {TrialsSchedule} from "../calendar/trialsSchedule";
import {ConfigEditor} from "../config/configEditor";
import {CreditTransferEditor} from "../enrolments/creditTransferEditor";
import {TrialEditor} from "../purchases/trialEditor";
import {PurchaseHistory} from "../purchases/purchaseHistory";
import {GoogleContactsTokenUpdater} from "../users/googleContactsTokenUpdater";
import {TeacherReplacementRequests} from "../teacherReplacementRequests/teacherReplacementRequests";
import {TeacherReplacementRequestWithdrawer} from "../teacherReplacementRequests/teacherReplacementRequestWithdrawer";
import {TeacherReplacementRequestEditor} from "../teacherReplacementRequests/teacherReplacementRequestEditor";
import {TransferConfirmation} from "../payslips/transferConfirmation";
import {UnearnedRevenuesByPayer} from "../payslips/unearnedRevenuesByPayer";
import {MeetingRecordings} from "../courses/meetingRecordings";
import {PayslipInvoicePage} from "../payslips/payslipInvoicePage";
import { Drills } from "../drills/drills";
import { DrillEditor } from "../drills/drillEditor";

export function App() {
  return (
    <Router history={history}>
      <div>
        <ErrorConsole/>
        <Route path="/" exact={true}>
          <Redirect to="/classRequests"/>
        </Route>
        <Route path="/dashboard" exact={true} component={Dashboard}/>
        <Route path="/login" exact={true} component={EmailLogin}/>
        <Route path="/signup" exact={true} component={Signup}/>
        <Route path="/login/email" exact={true} component={EmailLogin}/>
        <Route path="/classRequests" exact={true} component={ClassRequests}/>
        <Route path="/closedClassRequests" exact={true} render={(props) => <ClassRequests {...props} closed/>}/>
        <Route path="/classRequests/:classRequestId" exact={true} component={ClassRequestEditor}/>
        <Route path="/classRequests/:classRequestId/withdrawal" exact={true} component={ClassRequestWithdrawer}/>
        <Route path="/teacherReplacementRequests" exact={true} component={TeacherReplacementRequests}/>
        <Route path="/teacherReplacementRequests/new" exact={true} component={TeacherReplacementRequestEditor}/>
        <Route path="/teacherReplacementRequests/:teacherReplacementRequestId/withdrawal" exact={true} component={TeacherReplacementRequestWithdrawer}/>
        <Route path="/courseProposals" exact={true} component={CourseProposals}/>
        <Route path="/closedCourseProposals" exact={true} render={(props) => <CourseProposals {...props} closed/>}/>
        <Route path="/courseProposals/:courseProposalId" exact={true} component={CourseProposalEditor}/>
        <Route path="/testCalls/new" exact={true} component={TestCallEditor}/>
        <Route path="/testCallSchedule" exact={true} component={TestCallSchedule}/>
        <Route path="/trialsSchedule" exact={true} component={TrialsSchedule}/>
        <Route path="/courses" exact={true} component={Courses}/>
        <Route path="/allCourses" exact={true} component={Courses}/>
        <Route path="/allActiveCourses" exact={true} component={Courses}/>
        <Route path="/courses/:courseId" exact={true} component={CourseEditor}/>
        <Route path="/courses/:courseId/meetings" exact={true} component={CourseMeetings}/>
        <Route path="/recordings/meetings/:meetingId" exact={true} component={MeetingRecordings}/>
        <Route path="/recordings/meetings/" exact={true} component={MeetingRecordings}/>
        <Route path="/courses/:courseId/enrolments/:userId" exact={true} component={EnrolmentsEditor}/>
        <Route path="/courses/:courseId/nps" exact={true} component={CourseNpsComments}/>
        <Route path="/courses/:courseId/students/:studentId/purchaseHistory" exact={true} component={PurchaseHistory}/>
        <Route path="/creditTransfers/new" exact={true} component={CreditTransferEditor}/>
        <Route path="/renewals" exact={true} component={Renewals}/>
        <Route path="/renewals/:renewalId/withdrawal" exact={true} component={CourseRenewalWithdrawer}/>
        <Route path="/teachers" exact={true} component={Teachers}/>
        <Route path="/teachers/:teacherId" exact={true} component={TeacherEditor}/>
        <Route path="/teachers/:teacherId/payslips/new" exact={true} component={PayslipEditor}/>
        <Route path="/teachers/:teacherId/payslips" exact={true}><TeacherPayslips/></Route>
        <Route path="/teachers/:teacherId/schedule" exact={true}><TeacherSchedule/></Route>
        <Route path="/teacherApplications" exact={true} component={TeacherApplications}/>
        <Route path="/teacherApplications/:applicationId/teacherApplicationSchedule/new" exact={true} component={TeacherApplicationScheduleEditor}/>
        <Route path="/schedules" exact={true} component={Calendar}/>
        <Route path="/payslips" exact={true} component={Payslips}/>
        <Route path="/payslips/:payslipId" exact={true} component={PayslipDisplay}/>
        <Route path="/payslips/:payslipId/invoice" exact={true}><PayslipInvoicePage/></Route>
        <Route path="/unearnedRevenues" exact={true}><UnearnedRevenuesByPayer/></Route>
        <Route path="/transferConfirmation" exact={true} component={TransferConfirmation}/>
        <Route path="/teachers/:teacherId/notification" exact={true} component={TeacherNotification}/>
        <Route path="/users/:userId" exact={true} component={UserEditor}/>
        <Route path="/users" exact={true} component={UserPicker}/>
        <Route path="/packages/:packageId" exact={true} component={PackageEditor}/>
        <Route path="/meetings/:meetingId" exact={true} component={MeetingRescheduler}/>
        <Route path="/purchases/new" exact={true} component={PurchaseEditor}/>
        <Route path="/createTrial" exact={true} component={TrialEditor}/>
        <Route path="/reimbursements/new" exact={true} component={ReimbursementEditor}/>
        <Route path="/paymentRequests/new" exact={true} component={PaymentRequestEditor}/>
        <Route path="/waQrCode" exact={true} component={WhatsappQrCode}/>
        <Route path="/config" exact={true} component={ConfigEditor}/>
        <Route path="/googleContactsToken" exact={true} component={GoogleContactsTokenUpdater}/>
        <Route path="/drillEditor/:drillId" exact={true} component={DrillEditor}/>
        <Route path="/drills" exact={true} component={Drills}/>
      </div>
    </Router>
  )
}
