import {api} from './api';

export const quizApi = {
  getNpsAttemptsForCourse: async (courseId) => {
    return api.quizSvcRequest('GET', `/attempts?testId=nps&params.courseId=${courseId}`);
  },
  getAttemptsAfter: async (testId, whenMillis) => {
    return api.quizSvcRequest('GET', `/attempts?testId=${testId}&after=${whenMillis}`);
  },
  getDrill: async (id) => {
    return api.quizSvcRequest('GET', `/drills/${id}`);
  },
  updateDrill: async (drill) => {
    return api.quizSvcRequest('PUT', `/drills/${drill._id}`, drill);
  },
  getDrillsInfo: async () => {
    return api.quizSvcRequest('GET', `/drills`);
  },
};
