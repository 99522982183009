import React from 'react';
import logo from '../../img/cerah.png';
import style from '../payslips/payslipDisplay.scss';
import moment from "moment";
import {savePayslipsAsPdfs} from "./payslipPrinter";
import {currencyLabels} from "../config/currency";

export function PayslipInvoiceDisplay({payslip, teacher}) {
  if (!teacher?.address) {
    return <div style={{position: "fixed", left: 0, top: 0, fontSize: 16, color: "#f00"}}>NO ADDRESS FOR {teacher?.name}</div>
  }

  const tuitionTotal = payslip.meetings.reduce(totalFee, 0);
  const othersTotal = 0;
  const grandTotal = tuitionTotal + othersTotal;
  const periodStart = payslip.meetings.reduce(earliest, moment());
  const periodEnd = payslip.meetings.reduce(latest, moment(0));
  const taughtHoursByFee = totalDurationHoursByFee(payslip.meetings);
  const totalHoursTaught = payslip.meetings.reduce(totalDurationHours, 0);
  
  return (
    <div className={style.payslipDisplay}>
      <div className={style.inset}>

        <div style={{display: "flex", marginBottom: 30}}>
          <div style={{flex: 1}}>
            <div className={style.mainTitle} onClick={() => savePayslipsAsPdfs([payslip])}>PROFESSIONAL SERVICES INVOICE</div>
            <div className={style.subTitle}>INVOICE #{payslip.identifier}</div>
            <div className={style.invoiceDate}>Invoice date: {moment(payslip.creationTime).format("DD-MM-YYYY")}</div>
          </div>

          <div className={style.issuer}>
            <div className={style.issuerName}>{teacher && teacher.fullName}</div>
            {(teacher?.address).map(line => <div>{line}</div>)}
            {teacher?.taxId && <div>Tax ID: {teacher?.taxId}</div>}
          </div>
        </div>

        <div>Bill to:</div>
        <div className={style.payer}>
          <div>
            <img className={style.logo} src={logo}/>
            <div className={style.logoText}>CERAH</div>
          </div>
          <div className={style.companyDetails}>
            <div>Cerah Education Pte Ltd</div>
            <div>160 Robinson Rd #14-04</div>
            <div>Singapore 068914</div>
          </div>
        </div>
        
        <div>Period: {periodStart.format("DD-MM-YYYY")} - {periodEnd.format("DD-MM-YYYY")}</div>
        <table className={style.payslipTable}>
          <colgroup>
            <col style={{width: 10000}}/>
            <col style={{minWidth: 120}}/>
            <col style={{minWidth: 90}}/>
            <col style={{minWidth: 180}}/>
          </colgroup>
          <thead>
            <th>Description</th>
            <th>Unit price (IDR)</th>
            <th>Qty</th>
            <th>Amount (IDR)</th>
          </thead>
          {Object.entries(taughtHoursByFee).map(([fee, numHours]) =>
            <tr>
              <td>Language teaching</td>
              <td className={style.numeric}>@ {fee} / h</td>
              <td className={style.numeric}>{numHours} h</td>
              <td className={style.numeric}>
                {(fee * numHours).toLocaleString()}
              </td>
            </tr>
          )}
          <tr>
            <td colSpan="2" className={style.bigTotal}>TOTAL</td>
            <td className={`${style.numeric} ${style.bigTotal}`}>{totalHoursTaught} h</td>
            <td className={`${style.numeric} ${style.bigTotal}`}>
              IDR {grandTotal.toLocaleString()}
            </td>
          </tr>
          <tr>
            <td colSpan="3" className={style.bigTotal}>AMOUNT DUE</td>
            <td className={`${style.numeric} ${style.bigTotal}`}>
              {payslip.paid ? 0 : grandTotal.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </td>
          </tr>
          {payslip.settlementCurrency && <tr>
            <td colSpan="3" className={style.bigTotal}>PAID IN {currencyLabels[payslip.settlementCurrency].toUpperCase()}</td>
            <td className={`${style.numeric} ${style.bigTotal}`}>
              {payslip.paid ? (grandTotal * payslip.xrate).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0}
            </td>
          </tr>}
        </table>
        {payslip.xrate && payslip.xrate !== 1 && <div className={style.xrate}>Exchange rate on payment date: {(1 / payslip.xrate).toPrecision(5)} IDR / {payslip.settlementCurrency}</div>}
      </div>
    </div>
  );
}

const totalFee = (acc, m) => acc + m.fee;
const totalDurationHours = (acc, m) => acc + m.durationMins / 60;
const totalDurationHoursByFee = (meetings) => meetings.reduce((acc, m) => {
  const hourlyFee = m.fee / m.durationMins * 60;
  acc[hourlyFee] = acc[hourlyFee] || 0; 
  acc[hourlyFee] += m.durationMins / 60; 
  return acc; 
}, {}) ;
const earliest = (acc, m) => moment(m.startTime).isBefore(acc) ? moment(m.startTime) : acc;
const latest = (acc, m) => moment(m.startTime).isAfter(acc) ? moment(m.startTime) : acc;
