// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K7_OOWW7 {\n  display: inline; }\n  .K7_OOWW7 .qx0bF57n {\n    display: inline;\n    border: 1px solid #888;\n    width: auto;\n    margin: 5px;\n    font-family: monospace;\n    padding: 4px;\n    border-radius: 4px; }\n", "",{"version":3,"sources":["webpack://./src/courses/schedule.scss"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAA;EADjB;IAII,eAAe;IACf,sBAAsB;IACtB,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,kBAAkB,EAAA","sourcesContent":[".scheduleEntry {\n  display: inline;\n\n  .scheduleEntryPart {\n    display: inline;\n    border: 1px solid #888;\n    width: auto;\n    margin: 5px;\n    font-family: monospace;\n    padding: 4px;\n    border-radius: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scheduleEntry": "K7_OOWW7",
	"scheduleEntryPart": "qx0bF57n"
};
export default ___CSS_LOADER_EXPORT___;
