// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JeHSoQso {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px; }\n  .JeHSoQso .fG4fLuyW {\n    background-color: #ffcccc;\n    border: 1px solid #ff2222;\n    color: #ff2222;\n    text-align: center;\n    border-radius: 5px;\n    padding: 20px;\n    margin: 20px;\n    max-width: 600px; }\n  .JeHSoQso .qBrqYI9j {\n    width: 100%;\n    max-width: 400px;\n    margin: 0 auto; }\n  .JeHSoQso form {\n    display: flex;\n    flex-direction: column;\n    align-items: center; }\n    .JeHSoQso form input {\n      border: none;\n      border-bottom: 1px solid;\n      margin: 10px;\n      font-size: 20px; }\n", "",{"version":3,"sources":["webpack://./src/login/login.scss"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe,EAAA;EANjB;IASI,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,gBAAgB,EAAA;EAhBpB;IAoBI,WAAW;IACX,gBAAgB;IAChB,cAAc,EAAA;EAtBlB;IA0BI,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAA;IA5BvB;MA+BM,YAAY;MACZ,wBAAwB;MACxB,YAAY;MACZ,eAAe,EAAA","sourcesContent":["\n.login {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px;\n\n  .error {\n    background-color: #ffcccc;\n    border: 1px solid #ff2222;\n    color: #ff2222;\n    text-align: center;\n    border-radius: 5px;\n    padding: 20px;\n    margin: 20px;\n    max-width: 600px;\n  }\n\n  .loginBtn {\n    width: 100%;\n    max-width: 400px;\n    margin: 0 auto;\n  }\n\n  form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    input {\n      border: none;\n      border-bottom: 1px solid;\n      margin: 10px;\n      font-size: 20px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "JeHSoQso",
	"error": "fG4fLuyW",
	"loginBtn": "qBrqYI9j"
};
export default ___CSS_LOADER_EXPORT___;
