// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MXrnoDwo {\n  position: absolute;\n  transition: all 100ms ease-in-out;\n  width: 17px;\n  height: 17px;\n  border-radius: 50%;\n  background-color: indianred;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  cursor: pointer;\n  font-weight: bold;\n  font-size: 12px;\n  color: white;\n  right: 10px;\n  top: 10px;\n  z-index: 100; }\n  .MXrnoDwo:hover {\n    background-color: red;\n    transform: scale(1.2); }\n", "",{"version":3,"sources":["webpack://./src/controls/removeButton.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iCAAiC;EACjC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,2BAA2B;EAC3B,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,WAAW;EACX,SAAS;EACT,YAAY,EAAA;EAjBd;IAoBI,qBAAqB;IACrB,qBAAqB,EAAA","sourcesContent":[".button {\n  position: absolute;\n  transition: all 100ms ease-in-out;\n  width: 17px;\n  height: 17px;\n  border-radius: 50%;\n  background-color: indianred;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  cursor: pointer;\n  font-weight: bold;\n  font-size: 12px;\n  color: white;\n  right: 10px;\n  top: 10px;\n  z-index: 100;\n\n  &:hover {\n    background-color: red;\n    transform: scale(1.2);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "MXrnoDwo"
};
export default ___CSS_LOADER_EXPORT___;
