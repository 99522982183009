// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y4PpqErP {\n  width: 100%;\n  border-collapse: collapse; }\n  .Y4PpqErP thead {\n    background-color: #e6effc;\n    font-size: 12px;\n    text-transform: uppercase; }\n  .Y4PpqErP tr {\n    border: 1px solid #e6effc; }\n    .Y4PpqErP tr td {\n      padding: 2px; }\n      .Y4PpqErP tr td strong {\n        font-size: 12px;\n        text-transform: uppercase; }\n", "",{"version":3,"sources":["webpack://./src/controls/blueTable.scss","webpack://./src/theme.scss"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,yBAAyB,EAAA;EAF3B;IAII,yBCHmB;IDInB,eAAe;IACf,yBAAyB,EAAA;EAN7B;IASI,yBCRmB,EAAA;IDDvB;MAWM,YAAY,EAAA;MAXlB;QAcQ,eAAe;QACf,yBAAyB,EAAA","sourcesContent":["@import \"../theme.scss\";\n\n.blueTable {\n  width: 100%;\n  border-collapse: collapse;\n  thead {\n    background-color: $accent1-tint2;\n    font-size: 12px;\n    text-transform: uppercase;\n  }\n  tr {\n    border: 1px solid $accent1-tint2;\n    td {\n      padding: 2px;\n\n      strong {\n        font-size: 12px;\n        text-transform: uppercase;\n      }\n    }\n  }\n}","$main-color1: #fee773;\n$main-color2: #ffa23e;\n\n$accent1-tint2: #e6effc;\n$accent1-tint1: #bedbff;\n$accent1: #a5c0e5;\n$accent1-shade1: #84a5d1;\n$accent1-shade2: #455d89;\n$accent1-shade3: #2d3037;\n\n$accent2-tint2: #c1d7f4;\n$accent2-tint1: #77a5e1;\n$accent2: #4783d0;\n$accent2-shade1: #1b65c3;\n$accent2-shade2: #09448f;\n\n$accent3-tint2: #f9fbff;\n$accent3-tint1: #9dc1f0;\n$accent3: #5c95de;\n$accent3-shade1: #286fcb;\n$accent3-shade2: #09448f;\n\n$accent4-tint2: #e0e0e0;\n$accent4-tint1: #d6d6d6;\n$accent4: #bfc8e1;\n$accent4-shade1: #7281ae;\n$accent4-shade2: #47568a;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blueTable": "Y4PpqErP"
};
export default ___CSS_LOADER_EXPORT___;
