import React, {useState, useEffect} from 'react';
import {courseApi} from "../api/courseApi";
import {Formik, Form, Field} from 'formik';
import {Button} from "../controls/button";
import {NeatFormField} from "../controls/neatFormField";
import {PackageField} from "./packageField";
import {paymentApi} from "../api/paymentApi";
import {useLocation} from 'react-router';
import history from "../history/history";
import {CoursePackages} from "../courses/coursePackages";
import {logError} from "../errors/errorConsole";
import midtransImg from "../../img/midtrans.jpg";
import {Spinner} from "../controls/spinner";
import {observer} from "mobx-react";
import {usersStore} from "../store/usersStore";
import moment from "moment";
import style from './paymentRequestEditor.scss';
import {classRequestApi} from "../api/classRequestApi";
import {Schedule} from "../courses/course";

const wiseSupportedCcys = ["EUR", "GBP", "SGD"];

const paymentRequest = {
  userId: "",
  pkgId: "",
  studentIds: [],
  paymentMethod: ""
};

export function PaymentRequestEditor() {
  const {state} = useLocation();
  const course = state && state.course;
  const selectedPayerStudents = state && state.payerAndStudent;
  if (!course) {
    history.push('/courses');
    return;
  }

  const [pkgs, setPackages] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [formerPayers, setFormerPayers] = useState([]);
  const [newRequesters, setNewRequesters] = useState([]);

  async function loadState() {
    const openClassReqs = (await classRequestApi.findOpenClassRequestsForCourseProposal(course.courseProposalId))
      .map(cr => ({
        ...cr,
        studentIds: cr.studentIds || [cr.userId],
        type: "requester"
      }));
    const purchases = (await courseApi.getPurchasesForCourse(course._id)).reverse();
    const uniquePayerStudents = extractUniquePayerAndStudents([...openClassReqs, ...purchases]); //the ordering is critical: this way, if a new requester is enrolled, the request will survive, not the trial purchase
    setNewRequesters(uniquePayerStudents.filter(tuple => tuple.type === "requester"));
    setFormerPayers(uniquePayerStudents.filter(tuple => tuple.type !== "requester"));
    setPackages(await courseApi.searchPackagesByCourseId(course._id));
  }

  useEffect(() => {
    loadState();
  }, []);

  return (
    <div style={{padding: 10}}>
      <div>You're creating a PAYMENT LINK for course</div>
      <br/>
      <div>{course.name}</div>
      <Schedule course={course}/>
      <img style={{width: 180}}
           src={midtransImg}/>
      {!selectedPayerStudents && <div className={style.boldText}>SELECT PAYER / STUDENTS</div>}
      {!selectedPayerStudents && <div className={style.boldText}>New requesters</div>}
      {!selectedPayerStudents && newRequesters.map(payerStudents =>
        <PayerStudents payerStudents={payerStudents}
                       onClick={() => {
                         history.push(`/paymentRequests/new`, {
                           course: {_id: course._id, name: course.name, schedule: course.schedule, timezone: course.timezone},
                           payerAndStudent: payerStudents
                         })
                       }}
        />
      )}
      {!selectedPayerStudents && <div className={style.boldText}>Already enrolled</div>}
      {!selectedPayerStudents && formerPayers.map(payerStudents =>
        <PayerStudents payerStudents={payerStudents}
                       onClick={() => {
                         history.push(`/paymentRequests/new`, {
                           course: {_id: course._id, name: course.name, schedule: course.schedule, timezone: course.timezone},
                           payerAndStudent: payerStudents
                         })
                       }}
        />
      )}

      {selectedPayerStudents && <Formik
        initialValues={paymentRequest}
        onSubmit={submit}
      >
        {({submitForm, values}) => {
          const pkgCur = pkgs.filter(pkg => pkg._id === values.pkgId)[0]?.currency;
          const pkgPrice = pkgs.filter(pkg => pkg._id === values.pkgId)[0]?.price;
          return (
            <Form>
              <PayerStudents payerStudents={selectedPayerStudents}/>
              <NeatFormField name="pkgId"
                             label="Package to be purchased"
                             pkgs={pkgs}
                             component={PackageField}/>
              <CoursePackages course={course}
                              onPackagesChanged={loadState}/>
              <NeatFormField name="paymentMethod"
                             label="Payment Method"
                             component="select">
                  <option value="">Select method</option>
                  {wiseSupportedCcys.includes(pkgCur) && <option value="wiseTransfer">Transfer</option>}
                  {pkgCur !== "IDR" && <option value="card">Stripe</option>}
                  {pkgCur === "IDR" && pkgPrice !== 0 && <option value="xendit">Xendit</option>}
                  {((pkgCur !== "IDR" && !wiseSupportedCcys.includes(pkgCur)) || pkgPrice === 0)  && <option value="other">Rapyd</option>}
              </NeatFormField>
              <div>
                <Button noArrow={isSubmitting}
                        onClick={submitForm}
                        disabled={isSubmitting}
                        text={!isSubmitting && "Create payment link"}>
                  {isSubmitting && <Spinner/>}
                </Button>
              </div>
            </Form>
          );
      }}
      </Formik>}
    </div>
  );

  async function submit(paymentRequest) {
    if (isSubmitting) return;
    setSubmitting(true);
    try {
      paymentRequest.userId = selectedPayerStudents.userId;
      paymentRequest.studentIds = selectedPayerStudents.studentIds;
      const pymtRequest = await paymentApi.createPaymentRequest(paymentRequest);
      window.open(pymtRequest.redirect_url, "_blank");
      history.push('/courses');
    } catch (e) {
      logError("error creating payment request", e);
    }
    setSubmitting(false);
  }
}

export const PayerStudents = observer(
  function PayerStudents({payerStudents, onClick, selected}) {
    const payingUser = usersStore.getUserById(payerStudents.userId);
    const students = payerStudents.studentIds.map(stId => usersStore.getUserById(stId));
    return (
      <div className={
          `${style.payerStudents} ${selected ? style.selected : ""} ${onClick ? style.clickable: ""}`
        } onClick={onClick}>
        <div className={style.payer}>{payingUser ? <>{payingUser.name} <span className={style.country}>({payingUser.country})</span></> : "..."}<span className={style.voucher}>{payerStudents.voucher}</span></div>
        <div className={style.student}>for {students.map(st => st ? st.name : "...").join(", ")}</div>
        <div className={style.creationTime}>{moment(payerStudents.creationTime).format("DD-MM-YYYY HH:mm:ss")}</div>
      </div>
    );
  }
);


export function extractUniquePayerAndStudents(payerStudentsTuples) {
  if (payerStudentsTuples.length === 0)
    return []
  const uniqueTuples = [payerStudentsTuples[0]];
  for (let i = 1; i < payerStudentsTuples.length; i++) {
    let isSamePayerAndStudents = false;
    for (let j = 0; j < uniqueTuples.length; j++) {
      if (samePayerAndStudents(payerStudentsTuples[i], uniqueTuples[j])) {
        isSamePayerAndStudents = true
        break;
      }
    }
    if (!isSamePayerAndStudents) {
      uniqueTuples.push(payerStudentsTuples[i]);
    }
  }
  return uniqueTuples;
}

function samePayerAndStudents(p1, p2) {
  if (p1.userId !== p2.userId)
    return false;

  for (let s1 of p1.studentIds) {
    if (p2.studentIds.indexOf(s1) === -1)
      return false;
  }

  return true;
}