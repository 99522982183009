// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V6wejWjB {\n  padding: 20px;\n  width: 100%; }\n", "",{"version":3,"sources":["webpack://./src/courseProposals/courseProposalEditor.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW,EAAA","sourcesContent":[".classRequestEditor {\n  padding: 20px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"classRequestEditor": "V6wejWjB"
};
export default ___CSS_LOADER_EXPORT___;
