import React, { useEffect, useState } from 'react';
import {Menu} from "../menu/menu";
import homeStyle from '../main/home.scss';
import { quizApi } from '../api/quizApi';
import {ArrayField} from "../controls/arrayField";
import style from "./drillEditor.scss";
import {Formik, Form, Field} from "formik";
import {NeatFormField} from "../controls/neatFormField";
import {Spinner} from "../controls/spinner";
import history from "../history/history";

export function DrillEditor(props) {
  const {drillId} = props.match.params;
  const [drill, setDrill] = useState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    fetchDrill();
  }, []);

  async function fetchDrill() {
    const response = await quizApi.getDrill(drillId);
    setDrill(response);
  }

  async function submit(values) {
    const updatedDrill = values;
    setSubmitting(true);
    try {
      const res = await quizApi.updateDrill(updatedDrill);
      history.push("/drills");
    } catch (e) {
      console.log("Error updating drill", e);
    }
    setSubmitting(false);
  }

  return (
    <div className={homeStyle.home}>
      <Menu/>
      <div className={style.form}>
        {drill && !submitting &&
          <Formik
            initialValues={drill}
            onSubmit={submit}
            enableReinitialize={true}>
            
            <Form>
              <NeatFormField type="text" name="title" label="Title"/>
              <NeatFormField type="number" name="difficulty" label="Difficulty"/>
              <ArrayField innerComp={DrillItem} label="Drill Item" name="items"/>
              <ArrayField innerComp={Instructions} label="Instructions" name={"instructions"}/>
              <button type="submit">Submit</button>
            </Form>
          </Formik>
        }
        {submitting && <Spinner/>}
      </div>
    </div>
  );
}

function DrillItem({field: {name, onChange}}) {
  return (
    <Form onChange={onChange}>
      <div style={{border: "1px solid #bedbff", padding: "5px", borderRadius: "5px"}}>
        {name}
        <Field type="text" name={`${name}.prompt`} placeholder="Prompt"/>
        <ArrayField innerComp={DrillItemAnswers} label="Answers" name={`${name}.answers`}/>
      </div>
    </Form>
  )
}

function DrillItemAnswers({field: {name, onChange}}) {
  return (
    <Form onChange={onChange}>
      <Field type="text" name={name} placeholder="Answer"/>
    </Form>
  )
}


function Instructions({field: {name, onChange, value}}) {
  return (
    <Form onChange={onChange}>
      <Field type="text" name={`${name}.text`} placeholder="Instruction"/>
      {value.type == "examples" && <ArrayField innerComp={InstructionExamples} label="Examples" name={`${name}.examples`}/>}
    </Form>
  )
}

function InstructionExamples({field: {name, onChange, value}}) {
  return (
    <Form onChange={onChange}>
      <Field type="text" name={`${name}.prompt`} placeholder="Prompt"/>
      <ArrayField innerComp={InstructionExampleAnswer} label="Answers" name={`${name}.answers`}/>
    </Form>
  )
}

function InstructionExampleAnswer({field: {name, onChange}}) {
  return (
    <Form onChange={onChange}>
      <Field type="text" name={name} placeholder="Instruction Example Answer"/>
    </Form>
  )
}