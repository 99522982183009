import React, {useState, useEffect} from 'react';
import {courseApi} from "../api/courseApi";
import {Formik, Form, Field} from 'formik';
import {Button} from "../controls/button";
import {NeatFormField} from "../controls/neatFormField";
import {ArrayField} from "../controls/arrayField";
import {UserField} from "../users/userField";
import {useLocation} from 'react-router';
import history from "../history/history";
import {logError} from "../errors/errorConsole";
import {Spinner} from "../controls/spinner";

export function ReimbursementEditor() {
  const {state} = useLocation();
  const course = state && state.course;
  if (!course) {
    history.push('/courses');
    return;
  }

  const [isSubmitting, setSubmitting] = useState(false);

  const reimbursement = {
    courseId: course._id,
    userId: "",
    numHours: 0,
    amount: 0,
    studentIds: [],
    currency: "IDR",
  };

  return (
    <div style={{padding: 10}}>
      You're creating a REIMBURSEMENT for course {course.name}
      <Formik
        initialValues={reimbursement}
        onSubmit={submit}
      >
        {({submitForm}) => (
          <Form>
            <NeatFormField name="userId"
                           label="Payer"
                           component={UserField}/>
            <ArrayField name="studentIds"
                        label="Students"
                        innerComp={UserField}/>
            <NeatFormField name="numHours"
                           label="# hours"
                           type="number"/>
            <NeatFormField name="amount"
                           label="Amount to reimburse"
                           type="number"/>
            <div>
              <Button noArrow={isSubmitting}
                      onClick={submitForm}
                      disabled={isSubmitting}
                      text={!isSubmitting && "Reimburse"}>
                {isSubmitting && <Spinner/>}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  async function submit(reimbursement) {
    if (isSubmitting) return;
    setSubmitting(true);
    try {
      await courseApi.createReimbursement(reimbursement);
      history.push('/courses');
    } catch (e) {
      logError("error creating reimbursement", e);
    }
    setSubmitting(false);
  }
}