import React, {useState} from 'react';
import {usersStore} from "../store/usersStore";
import {classRequestApi} from "../api/classRequestApi";
import {observer} from "mobx-react";
import classRequestStyle from '../classRequests/classRequests.scss';
import style from './courseProposals.scss';
import {Menu} from "../menu/menu";
import homeStyle from '../main/home.scss';
import * as moment from "moment";
import {Button} from "../controls/button";
import {userSession} from "../api/userSession";
import {teachersStore} from "../store/teachersStore";
import {Link} from "react-router-dom";
import {Spinner} from "../controls/spinner";
import {groupById, groupByProperty} from "../util/groupById";
import {courseProposalTypes} from "./courseProposalEditor";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {coursesStore} from "../store/coursesStore";
import {config} from '../config';
import history from '../history/history';
import {logError} from "../errors/errorConsole";
import {Schedule} from "../courses/course";
import {courseApi} from "../api/courseApi";
import {notificationApi} from "../api/notificationApi";

@observer
export class CourseProposals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      courseProposals: [],
      firstClassRequests: {}
    };
    this.loadState();
  }

  async loadState() {
    this.setState({
      loading: true,
      courseProposals: [],
      selected: {}
    });
    const courseProposals = (this.props.closed ?
        await classRequestApi.findClosedCourseProposals() :
        await classRequestApi.findOpenCourseProposals()
      ).reverse() || [];
    const allClassRequestIds = courseProposals.map(cp => cp.classRequestIds || []).flat();
    const classRequests = groupById(await classRequestApi.getClassRequestsByIds(allClassRequestIds));
    const testCalls = await this.retrieveTestCalls(allClassRequestIds);
    this.setState({
      courseProposals,
      classRequests,
      testCalls,
      loading: false
    });
    courseProposals.forEach(cp => {
      if (cp.classRequestIds) {
        cp.classRequestIds.forEach((crId) => {
          if (!classRequests[crId]) {
            logError(`courseProposal ${cp.name} points to nonexistent request ${crId}`);
          }
        });
      }
    })
  }

  async retrieveTestCalls(classRequestIds) {
    const testCalls = {};
    classRequestIds.forEach(crId => testCalls[crId] = []);
    const testCallsArr = await classRequestApi.getNotCancelledTestCallsByClassRequestsIds(classRequestIds);
    testCallsArr.forEach(call => testCalls[call.classRequestId].push(call));
    return testCalls;
  }

  render() {
    const st = this.state;
    st.courseProposals.forEach(proposal => {
      const course = coursesStore.activeCourses.filter(c => c.courseProposalId === proposal._id)[0];
      proposal.courseId = course && course._id; //FIXME: hack, this should be in the database once the course is created.
    });
    const [
      proposalsTrialFinished,
      proposalsTrialScheduled,
      proposalsWithTeacher,
      proposalsWithoutTeacher,
      teacherReplacementProposals
    ] = this.classifyProposals();
    const acceptingTeacher = this.state.acceptingTeacher;
    const creatingTrialForSuccessfulCall = this.state.creatingTrialForSuccessfulCall;
    const markingCallSuccessful = this.state.markingCallSuccessful;
    const sendingTestCallRescheduleLink = this.state.sendingTestCallRescheduleLink;
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.list}>
          <div className={classRequestStyle.classRequests}>
            {st.loading &&
              <Spinner/>}
            <div className={classRequestStyle.subsectionHeader}>Trial finished</div>
            {proposalsTrialFinished.map(proposal =>
              <CourseProposal courseProposal={proposal}
                              closed={this.props.closed}
                              classRequests={(proposal.classRequestIds || []).map(crId => st.classRequests[crId])}
                              latestTestCalls={(proposal.classRequestIds || []).map(crId => st.testCalls[crId][st.testCalls[crId].length - 1])}
                              onCallSuccessful={this.markCallSuccessful}
                              onCallReschedule={this.sendRescheduleLink}
              />
            )}
            <div className={classRequestStyle.subsectionHeader}>Trial scheduled</div>
            {proposalsTrialScheduled.map(proposal =>
              <CourseProposal courseProposal={proposal}
                              closed={this.props.closed}
                              classRequests={(proposal.classRequestIds || []).map(crId => st.classRequests[crId])}
                              latestTestCalls={(proposal.classRequestIds || []).map(crId => st.testCalls[crId][st.testCalls[crId].length - 1])}
                              onCallSuccessful={this.markCallSuccessful}
                              onCallReschedule={this.sendRescheduleLink}
              />
            )}
            <div className={classRequestStyle.subsectionHeader}>Teacher selected</div>
            {proposalsWithTeacher.map(proposal =>
              <CourseProposal courseProposal={proposal}
                              closed={this.props.closed}
                              classRequests={(proposal.classRequestIds || []).map(crId => st.classRequests[crId])}
                              latestTestCalls={(proposal.classRequestIds || []).map(crId => st.testCalls[crId][st.testCalls[crId].length - 1])}
                              onCallSuccessful={this.markCallSuccessful}
                              onCallReschedule={this.sendRescheduleLink}
              />
            )}
            <div className={classRequestStyle.subsectionHeader}>No teacher selected yet</div>
            {proposalsWithoutTeacher.map(proposal =>
              <CourseProposal courseProposal={proposal}
                              closed={this.props.closed}
                              classRequests={(proposal.classRequestIds || []).map(crId => st.classRequests[crId])}
                              latestTestCalls={(proposal.classRequestIds || []).map(crId => st.testCalls[crId][st.testCalls[crId].length - 1])}
                              onTeacherAccepted={(t) => this.acceptTeacher(proposal, t)}
                              onCallSuccessful={this.markCallSuccessful}
                              onCallReschedule={this.sendRescheduleLink}
              />
            )}
            <div className={classRequestStyle.subsectionHeader}>Teacher Replacement Proposal</div>
            {teacherReplacementProposals.map(proposal =>
              <CourseProposal courseProposal={proposal}
                              closed={this.props.closed}
                              classRequests={(proposal.classRequestIds || []).map(crId => st.classRequests[crId])}
                              latestTestCalls={(proposal.classRequestIds || []).map(crId => st.testCalls[crId][st.testCalls[crId].length - 1])}
                              onTeacherAccepted={(t) => this.acceptTeacher(proposal, t)}
                              onCallSuccessful={this.markCallSuccessful}
                              onCallReschedule={this.sendRescheduleLink}
              />
            )}
            {!st.loading && st.courseProposals.length === 0 && <div>No {this.props.closed ? "closed" : "open"} course proposals</div>}
          </div>
        </div>
        {acceptingTeacher &&
          <ConfirmationDialog message={`You're about to accept course proposal ${acceptingTeacher.courseProposal.name} on ` +
                                     `behalf of teacher ${acceptingTeacher.teacher.name}. This cannot be reversed. Are you sure?`}
                              onConfirm={this.onConfirmAcceptTeacher}
                              onReject={this.onCancelAcceptTeacher}/>
        }
        {creatingTrialForSuccessfulCall &&
          <ConfirmationDialog message={`Requester ${usersStore.getUserById(creatingTrialForSuccessfulCall.classRequest.userId)?.name} does not have a trial yet. Do you want to create one?`}
                              onConfirm={this.onConfirmCreateTrial}
                              onReject={this.onCancelCreateTrial}/>
        }
        {markingCallSuccessful &&
          <ConfirmationDialog message={this.isCallInFuture(markingCallSuccessful.call) ? `This call seems to be in the future, it'll be rescheduled to now and marked as successful. Are you sure?`:`This will mark the test call as successful. Are you sure?`}
                              onConfirm={this.onConfirmMarkCallSuccessful}
                              onReject={this.onCancelMarkCallSuccessful}/>
        }
        {sendingTestCallRescheduleLink &&
          <ConfirmationDialog message={`This will send a self-scheduling test call link to the student. Are you sure?`}
                              onConfirm={this.onConfirmSendRescheduleLink}
                              onReject={this.onCancelSendRescheduleLink}/>
        }
      </div>
    );
  }

  classifyProposals() {
    const st = this.state;
    const now = moment();
    const proposalsTrialFinished = [],teacherReplacementProposals = [], proposalsTrialScheduled = [], proposalsWithoutTeacher = [], proposalsWithTeacher = [];
    st.courseProposals.forEach(cp => {
      if (cp.teacherReplacementRequestId) return teacherReplacementProposals.push(cp);
      let trial;
      if (cp.courseId) {
        const course = coursesStore.coursesById[cp.courseId];
        trial = course && course.scheduledMeetings[0];
      }

      if (trial) {
        if (moment(trial.startTime).isBefore(now)) {
          proposalsTrialFinished.push(cp);
        } else {
          proposalsTrialScheduled.push(cp)
        }
      } else {
        if (cp.chosenTeacherId) {
          proposalsWithTeacher.push(cp);
        } else {
          proposalsWithoutTeacher.push(cp);
        }
      }
    });
    proposalsTrialFinished.sort(byTrialDate);
    proposalsTrialScheduled.sort(byTrialDate);
    return [proposalsTrialFinished, proposalsTrialScheduled, proposalsWithTeacher, proposalsWithoutTeacher, teacherReplacementProposals];
  }

  acceptTeacher = (cp, t) => {
    this.setState({acceptingTeacher: {courseProposal: cp, teacher: t}});
  };

  onConfirmAcceptTeacher = async () => {
    const {courseProposal, teacher} = this.state.acceptingTeacher;
    try {
      await classRequestApi.acceptTeacher(courseProposal._id, teacher._id);
      this.loadState();
    } catch (e) {
      logError(`Failed to accept teacher ${teacher.name} for courseProposal ${courseProposal.name}`, e);
    }
    this.hideTeacherAcceptanceModal();
  };

  onCancelAcceptTeacher = async () => {
    this.hideTeacherAcceptanceModal()
  };

  hideTeacherAcceptanceModal = () => {
    this.setState(curSt => {
      delete curSt.acceptingTeacher;
      return curSt;
    });
  };

  markCallSuccessful = async (call, classRequest) => {
    if (this.isUpdatingCall) return;
    try {
      if (!(await trialExistsForClassRequest(classRequest))) {
        this.setState({creatingTrialForSuccessfulCall: {call, classRequest}});
        return;
      }
      this.setState({markingCallSuccessful: {call}});
    } catch (e) {
      logError("error marking call as successful", e);
    }
  }

  isCallInFuture = (call) => {
    return moment(call.startTime).isAfter(moment());
  }

  onConfirmMarkCallSuccessful = async () => {
    let {call} = this.state.markingCallSuccessful;
    try {
      this.isUpdatingCall = true;
      if (this.isCallInFuture(call)) {
        call = await classRequestApi.createTestCall({...call, startTime: moment().toISOString()});
      }
      await classRequestApi.markCallAsSuccessful(call);
      this.loadState();
    } catch (e) {
      logError("error marking call as successful", e);
    }
    this.isUpdatingCall = false;
    this.hideMarkCallSuccesfulModal();
  };

  onCancelMarkCallSuccessful = () => {
    this.hideMarkCallSuccesfulModal();
  }

  hideMarkCallSuccesfulModal = async () => {
    this.setState(curSt => {
      delete curSt.markingCallSuccessful;
      return curSt;
    });
  }

  sendRescheduleLink = (testCall, classRequest) => {
    this.setState({sendingTestCallRescheduleLink: {testCall, classRequest}});
  }

  onConfirmSendRescheduleLink = async () => {
    const {testCall, classRequest} = this.state.sendingTestCallRescheduleLink;
    await sendRescheduleLinkToStudent(testCall, classRequest);
    this.hideSendRescheduleLinkModal();
  }

  onCancelSendRescheduleLink = () => {
    this.hideSendRescheduleLinkModal();
  }

  hideSendRescheduleLinkModal = () => {
    this.setState(curSt => {
      delete curSt.sendingTestCallRescheduleLink;
      return curSt;
    });
  }

  onConfirmCreateTrial = async () => {
    const {call, classRequest} = this.state.creatingTrialForSuccessfulCall;
    const {courseProposalId} = classRequest;
    const course = courseProposalId && (await courseApi.getCoursesByCourseProposalId(courseProposalId))[0];
    if (!course) {
      logError(`Could not create a trial meeting for ${usersStore.getUserById(classRequest.userId)} because no course has been created yet for this proposal`)
      this.hideCreateTrialModal();
      return;
    }
    try {
      await courseApi.createTrialPurchase({
        courseId: course._id,
        userId: classRequest.userId,
        studentIds: classRequest.studentIds || [classRequest.userId]
      });
      this.markCallSuccessful(call, classRequest)
    } catch (e) {
      logError(`Failed to create trial for ${usersStore.getUserById(classRequest.userId)} in course ${course.name}`, e);
    }
    this.hideCreateTrialModal();
  };

  onCancelCreateTrial = async () => {
    const {call} = this.state.creatingTrialForSuccessfulCall;
    this.setState({markingCallSuccessful: {call}})
    this.hideCreateTrialModal();
  }

  hideCreateTrialModal = () => {
    this.setState(curSt => {
      delete curSt.creatingTrialForSuccessfulCall;
      return curSt;
    });
  };
}

async function trialExistsForClassRequest(classRequest) {
  const {courseProposalId} = classRequest;
  const course = courseProposalId && (await courseApi.getCoursesByCourseProposalId(courseProposalId))[0];
  if (!course) {
    throw "There's no course for this proposal."
  }
  const trialPurchases = await courseApi.getTrialPurchasesForCourse(course._id);
  const studentsInReq = classRequest.studentIds || [classRequest.userId];
  let trialExists = false;
  for (let tp of trialPurchases) {
    if (tp.studentIds.every(stId => studentsInReq.includes(stId))) {
      trialExists = true;
      break;
    }
  }
  return trialExists;
}

const CourseProposal = observer(
  function CourseProposal({courseProposal: proposal, classRequests, latestTestCalls, highlighted, onClick, onTeacherAccepted, onCallSuccessful, onCallReschedule}) {

    function scheduleCall(student, trial, classRequest) {
      history.push({pathname: "/testCalls/new" , state: {studentId: student && student._id, trialTime: trial && trial.startTime, classRequest}});
    }

    const isGroup = [courseProposalTypes.onlineGroup, courseProposalTypes.bareng].includes(proposal.type);
    const payers = classRequests.map(cr => usersStore.getUserById(cr?.userId));
    const students = classRequests.map(cr => (cr?.studentIds || [cr?.userId])?.map(stId => usersStore.getUserById(stId)));
    const course = proposal.courseId && coursesStore.coursesById[proposal.courseId];
    const trial = course && course.scheduledMeetings[0];
    const testCalls = latestTestCalls || [];
    const dateToDisplay =
      trial && `trial on ${moment(trial.startTime).format("DD-MMM HH:mm")}`
      || moment(proposal.creationTime).format("DD-MMM HH:mm:ss");
    return (
      <div className={`${classRequestStyle.classRequest} ${highlighted && classRequestStyle.highlighted}`}
           onClick={onClick}>
        {isGroup ? '👥 ' : '👤 '}
        <div className={classRequestStyle.name}>{proposal.name}</div>
        <div className={classRequestStyle.level}>{proposal.level}</div>
        <div className={classRequestStyle.timestamp}>{dateToDisplay}</div>

        <div className={classRequestStyle.acceptedAges}>
          {(!proposal.age[1] || proposal.age[0] >= 18) ? (isGroup ? "adults" : "adult") : `${proposal.age[0]} - ${proposal.age[1]}`}
        </div>

        <div style={{marginLeft: 20, marginBottom: 6}}>
          <Schedule courseProposal={proposal}/>
        </div>

        <table className={`${classRequestStyle.students} ${payers.length > 1 && classRequestStyle.multiple}`}>
          {payers.map((payer, idx) =>
            <tr className={classRequestStyle.student}>
              {payer && <><td>{payer.name} ({students[idx]?.map(st => st?.name?.split(" ")[0]).join(", ")})</td><td>{payer.phone}</td></>}
              <CallScheduler call={testCalls[idx]}
                             onSchedule={() => scheduleCall(payer, trial, classRequests[idx])}
                             onCallSuccessful={() => onCallSuccessful(testCalls[idx], classRequests[idx])}
                             onCallReschedule={() => onCallReschedule(testCalls[idx], classRequests[idx])}/>
            </tr>
          )}
        </table>

        <TeacherResponses courseProposal={proposal} onTeacherAccepted={onTeacherAccepted}/>

        {!closed && <div>
          <Button className={classRequestStyle.classRequestButton}>
            <Link to={{pathname: `/courseProposals/${proposal._id}`, state: {courseProposal: proposal}}}>
              Edit
            </Link>
          </Button>
          {!proposal.courseId && proposal.chosenTeacherId &&
            <Button className={classRequestStyle.classRequestButton}>
              <Link to={{pathname: `/courses/new`, state: {course: createCourseFromCourseProposal(proposal)}}}>
                Create course
              </Link>
            </Button>
          }
          {!proposal.chosenTeacherId &&
            <a href={`https://${config.dev ? 'dev.' : 'www.'}cerah.co/courseProposals/${proposal._id}`}
               onClick={e => e.stopPropagation()}
               target="_blank">
              <Button className={classRequestStyle.classRequestButton} text="Choose teacher"/>
            </a>
          }
        </div>}
      </div>
    );
  }
);

function CallScheduler({call, onSchedule, onCallSuccessful, onCallReschedule}) {
  const [submittingSuccess, setSubmittingSuccess] = useState(false, []);

  async function submitSuccess() {
    setSubmittingSuccess(true);
    try {
      await onCallSuccessful();
    } catch (e) {
      logError("Failed to mark test call as successful", e);
    }
    setSubmittingSuccess(false);
  }

  return (<>
    {call && (
      <>
      <td>&nbsp;📞 {moment(call.startTime).format("DD-MMM HH:mm")}</td>
      {
        call.success === 'Y' ?
          <td className={style.callSuccess}>&nbsp;✓</td> :
        call.success === 'N' ?
          <td className={style.callFailure}>&nbsp;✗</td> :
          <td>
            <Button className={classRequestStyle.tinyButton}
                    onClick={!submittingSuccess && submitSuccess}>
              {submittingSuccess ? <Spinner className={style.spinner}/> : "call successful"}
            </Button>
          </td>
      }
      </>
    )}
    {onSchedule && !(call && call.success) && (<td>
      <Button className={classRequestStyle.tinyButton}
              text={call ? "move call" : "new call"}
              onClick={() => onSchedule()}/>
    </td>)}
    {onSchedule && !(call && call.success) && (<td>
      {<Button className={classRequestStyle.tinyButton}
              text={call ? "self-reschedule" : "self-schedule"}
              onClick={() => onCallReschedule()}/>}
    </td>)}
  </>);
}

const TeacherResponses = observer(
  function TeacherResponses({courseProposal: proposal, onTeacherAccepted}) {
    const teachers = (proposal.visibleTo && proposal.visibleTo.map(tId => teachersStore.getTeacherById(tId) || {})) || [];
    const chosenTeacherId = proposal.chosenTeacherId;
    const acceptedBy = proposal.acceptedBy && Object.keys(proposal.acceptedBy).map(tId => teachersStore.getTeacherById(tId) || {})
        .sort((t1, t2) => t1._id === chosenTeacherId ? -1 : 1);
    const rejectedBy = proposal.rejectedBy && Object.keys(proposal.rejectedBy).map(tId => teachersStore.getTeacherById(tId) || {});
    const responsePending = teachers.filter(t => !(rejectedBy && rejectedBy.includes(t)) && !(acceptedBy && acceptedBy.includes(t)));

    return (
      <div className={style.teachers}>
        {acceptedBy && acceptedBy.map(t =>
          <div className={`${style.teacher} ${chosenTeacherId && (t._id === chosenTeacherId ? style.picked : style.notPicked)}`}
               key={t._id}>
            ✔ {t.name}
          </div>
        )}
        {rejectedBy && rejectedBy.map(t =>
          <div className={`${style.teacher} ${style.rejected} ${chosenTeacherId && style.notPicked}`}
               key={t._id}>
            ✗ {t.name}
          </div>
        )}
        {responsePending.map(t =>
          <div className={`${style.teacher} ${style.responsePending} ${chosenTeacherId && style.notPicked}`}
               key={t._id}
               onClick={() => onTeacherAccepted && onTeacherAccepted(t)}>
            {t.name}
          </div>
        )}
      </div>
    );
  }
);

function createCourseFromCourseProposal({
  _id,
  name,
  startDate,
  subject,
  schedule,
  location,
  expectedDurationHours,
  level,
  type,
  timezone,
  chosenTeacherId,
  age,
}) {
  if (type === "onlineGroup") {
    subject = "english.bareng." + level;
  }
  return {
    name,
    startDate,
    subject,
    schedule,
    location,
    expectedDurationHours,
    type,
    timezone,
    teacherId: chosenTeacherId,
    courseProposalId: _id,
    acceptedAges: age,
  }
}

function byTrialDate(cp1, cp2) {
  const trial1 = getTrial(cp1) || {};
  const trial2 = getTrial(cp2) || {};
  return trial1.startTime > trial2.startTime ? 1 : -1;
}

function getTrial(cp) {
  const course = coursesStore.coursesById[cp.courseId];
  return course && course.scheduledMeetings[0];
}

export async function sendRescheduleLinkToStudent(testCall, classRequest) {
  let scheduleLink;
  try {
    const {updateToken} = await classRequestApi.getClassRequestGuestTokens(classRequest._id, 86400 * 14);
    scheduleLink = `https://${config.dev ? 'dev.' : 'www.'}cerah.co/testcall/reschedule?classRequestId=${classRequest._id}&updateToken=${updateToken}`;
  } catch (e) {
    logError("Error retrieving guest token for the class request", e);
    return;
  }

  try {
    const shortLink = (await classRequestApi.getShortenedUrl(scheduleLink)).shortUrl;
    await notificationApi.sendTemplatedNotificationToStudent(
      classRequest.userId,
      `${classRequest._id} ${shortLink}- Test Call Reschedule Notification`,
      'TEST_CALL_SCHEDULE_CHANGE',
      {testCallScheduleLink: shortLink}
    );
  } catch (e) {
    logError("Could not send message to student for rescheduling test call.", e);
  }
}
