// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t4kp29em {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  background-color: transparent;\n  display: flex;\n  flex-direction: column-reverse;\n  z-index: 100; }\n  .t4kp29em .XUmcxo7F {\n    background-color: #ffdddd;\n    color: red;\n    border: 2px solid red;\n    border-radius: 6px;\n    margin: 5px;\n    padding: 20px;\n    position: relative; }\n    .t4kp29em .XUmcxo7F .pm1iIqYa {\n      position: absolute;\n      right: 0;\n      width: 30px;\n      top: 0;\n      bottom: 0;\n      font-size: 30px;\n      display: flex;\n      align-items: center;\n      cursor: pointer; }\n", "",{"version":3,"sources":["webpack://./src/errors/errorConsole.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,6BAA6B;EAC7B,aAAa;EACb,8BAA8B;EAC9B,YAAY,EAAA;EAPd;IAUI,yBAAyB;IACzB,UAAU;IACV,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,kBAAkB,EAAA;IAhBtB;MAmBM,kBAAkB;MAClB,QAAQ;MACR,WAAW;MACX,MAAM;MACN,SAAS;MACT,eAAe;MACf,aAAa;MACb,mBAAmB;MACnB,eAAe,EAAA","sourcesContent":[".errorConsole {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  background-color: transparent;\n  display: flex;\n  flex-direction: column-reverse;\n  z-index: 100;\n\n  .error {\n    background-color: #ffdddd;\n    color: red;\n    border: 2px solid red;\n    border-radius: 6px;\n    margin: 5px;\n    padding: 20px;\n    position: relative;\n\n    .close {\n      position: absolute;\n      right: 0;\n      width: 30px;\n      top: 0;\n      bottom: 0;\n      font-size: 30px;\n      display: flex;\n      align-items: center;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorConsole": "t4kp29em",
	"error": "XUmcxo7F",
	"close": "pm1iIqYa"
};
export default ___CSS_LOADER_EXPORT___;
