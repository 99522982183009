// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UvZlMD3D {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  height: 0; }\n  .UvZlMD3D .iWKxaoEs {\n    position: absolute;\n    bottom: 0;\n    left: 4px;\n    cursor: pointer; }\n  .UvZlMD3D .LaKvEuMz {\n    position: absolute;\n    bottom: 0;\n    right: 4px;\n    cursor: pointer; }\n", "",{"version":3,"sources":["webpack://./src/calendar/testCallSchedule.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,SAAS,EAAA;EAJX;IAOI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,eAAe,EAAA;EAVnB;IAcI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,eAAe,EAAA","sourcesContent":[".rescheduleButtonsContainer {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  height: 0;\n\n  .selfRescheduleBtn {\n    position: absolute;\n    bottom: 0;\n    left: 4px;\n    cursor: pointer;\n  }\n\n  .rescheduleRedirectBtn {\n    position: absolute;\n    bottom: 0;\n    right: 4px;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rescheduleButtonsContainer": "UvZlMD3D",
	"selfRescheduleBtn": "iWKxaoEs",
	"rescheduleRedirectBtn": "LaKvEuMz"
};
export default ___CSS_LOADER_EXPORT___;
