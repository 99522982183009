// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zf3X4MOZ {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start; }\n  .Zf3X4MOZ .e6IxUsv3 {\n    font-size: 12px;\n    margin: 10px;\n    width: 200px; }\n", "",{"version":3,"sources":["webpack://./src/classRequests/classRequestWithdrawer.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAA;EAHzB;IAMI,eAAe;IACf,YAAY;IACZ,YAAY,EAAA","sourcesContent":[".reasonsList {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n\n  .reasonButton {\n    font-size: 12px;\n    margin: 10px;\n    width: 200px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reasonsList": "Zf3X4MOZ",
	"reasonButton": "e6IxUsv3"
};
export default ___CSS_LOADER_EXPORT___;
