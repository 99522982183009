import React, {useEffect, useState} from 'react';
import {coursesStore, monthIdx} from "../store/coursesStore";
import style from './unearnedRevenues.scss';
import {observer} from "mobx-react";
import {usersStore} from "../store/usersStore";
import {accountingApi} from "../api/accountingApi";
import {autorun} from "mobx";
import moment from "moment";

const monthsToSkip = 36;

export const UnearnedRevenuesByPayer = observer(
  function UnearnedRevenuesByStudent(props) {
    const [purchasesToSgByCoursePayerAndMonth, setPurchasesToSgByCoursePayerAndMonth] = useState({});
    const [unearnedRevenueInAccounting, setUnearnedRevenueInAccounting] = useState({});
    const [unearnedRevenueByPayerCourseAndMonth, setUnearnedRevenueByPayerCourseAndMonth] = useState({});
    const [consumedCreditByPayerCourseAndMonth, setConsumedCreditByPayerCourseAndMonth] = useState({});
    const [showOnlyMismatches, setShowOnlyMismatches] = React.useState(false);
  
    const nowMmnt = moment("2022-08-31T23:59:59.999+08:00");
    const currMonth = monthIdx(nowMmnt);
    const initialMonth = moment(nowMmnt).subtract(currMonth, "months");
    const monthLabels = new Array(currMonth + 1).fill("").map((dum, idx) => moment(initialMonth).add(idx, "months").format("MM-YYYY")).slice(monthsToSkip);
  
    useEffect(() => {
      autorun(() => {
        if (coursesStore.coursesLoading) return;
  
        const purchasesToSgByCoursePayerAndMonth = {};
        
        coursesStore.allCourses.filter(c => c.totalRevenueIDR > 10).forEach(c => {
          const {purchasesToSgByPayerAndMonth} = c.purchasesToSgByMonth
          purchasesToSgByCoursePayerAndMonth[c._id] = purchasesToSgByPayerAndMonth;
        });
        
        setPurchasesToSgByCoursePayerAndMonth(purchasesToSgByCoursePayerAndMonth);
      });
    }, [])
    
    
    useEffect(() => {
      autorun(() => {
        const unearnedRevenueByPayerCourseAndMonth = {};
        const consumedCreditByPayerCourseAndMonth = {};
        Object.entries(purchasesToSgByCoursePayerAndMonth).forEach(([cId, purchasesByPayerAndMonth]) => {
          const course = coursesStore.coursesById[cId];
          Object.keys(purchasesByPayerAndMonth).forEach(payerId => {
            unearnedRevenueByPayerCourseAndMonth[payerId] = unearnedRevenueByPayerCourseAndMonth[payerId] || {};
            unearnedRevenueByPayerCourseAndMonth[payerId][cId] = course.unearnedRevenueByPayerAndMonth[payerId];
            consumedCreditByPayerCourseAndMonth[payerId] = consumedCreditByPayerCourseAndMonth[payerId] || {};
            consumedCreditByPayerCourseAndMonth[payerId][cId] = course.earnedRevenueByPayerAndMonth[payerId];
          });
        });
        setUnearnedRevenueByPayerCourseAndMonth(unearnedRevenueByPayerCourseAndMonth);
        setConsumedCreditByPayerCourseAndMonth(consumedCreditByPayerCourseAndMonth);
      });
    }, [purchasesToSgByCoursePayerAndMonth]);
  
    useEffect(() => {
      loadAccounting()
    }, [unearnedRevenueByPayerCourseAndMonth]);
  
    async function loadAccounting() {
      const allPayersIds = Object.keys(unearnedRevenueByPayerCourseAndMonth);
      if (allPayersIds.length > 0)
        setUnearnedRevenueInAccounting(await accountingApi.getUnrealizedSales(allPayersIds, nowMmnt.valueOf()));
    }
  

    return (
      <div>
        <label>
          <input type="checkbox" checked={showOnlyMismatches} onChange={ev => setShowOnlyMismatches(v => !v)}/>
          mismatches only
        </label>
        <div className={style.mainTitle}>UNEARNED REVENUE BY PAYER</div>
        <table className={style.unearnedRevTable}>
          <thead>
            <tr>
              <th/>
              {monthLabels.map(mthLabel => <>
                <th colSpan={3}>{mthLabel}</th>
              </>)}
            </tr>
            <tr>
              <th>Course</th>
              {monthLabels.map(label => <>
                <th>credit purchased</th>
                <th>credit consumed</th>
                <th>unearned rev</th>
              </>)}
              <th>In books</th>
            </tr>
          </thead>
          {Object.keys(unearnedRevenueByPayerCourseAndMonth).map(payerId => {
            const payer = usersStore.getUserById(payerId);
            const creditByCourseAndMonth = unearnedRevenueByPayerCourseAndMonth[payerId];
            const courseIds = Object.keys(creditByCourseAndMonth);
            const creditXero = unearnedRevenueInAccounting[payerId] || 0;
            const creditOps = Object.values(creditByCourseAndMonth).map(crByMonth => crByMonth && crByMonth[currMonth] || []).flat().reduce((a, b) => a + b, 0);
            const mismatch = Math.abs(creditXero - creditOps) > 0.30 || creditOps !== creditOps; //highlight also if credit is NaN (because (NaN !== NaN) is true)
            return ((mismatch || !showOnlyMismatches) && <>
              <tr className={style.payer}>
                <td colSpan={999}>{payer?.name} - {payer?._id}</td>
              </tr>
              {courseIds.map((cId, idx) => {
                const creditSpentPerMonth = consumedCreditByPayerCourseAndMonth[payerId][cId] || {};
                const course = coursesStore.coursesById[cId];
                return (
                  <tr>
                    <td>{course?.name}</td>
                    {monthLabels.map((label, idx) => {
                      const month = idx + monthsToSkip;
                      return <>
                        <td style={{backgroundColor: "f0f0f0"}} className={style.numeric}>
                          {purchasesToSgByCoursePayerAndMonth[cId][payerId][month]?.toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) || ""}
                        </td>
                        <td style={{backgroundColor: "e0e0e0"}} className={style.numeric}>
                          {creditSpentPerMonth[month]?.toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) || ""}
                        </td>
                        <td style={{backgroundColor: "d0d0d0"}} className={style.numeric}>
                          {unearnedRevenueByPayerCourseAndMonth[payerId][cId] && unearnedRevenueByPayerCourseAndMonth[payerId][cId][month]?.toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </td>
                      </>
                    })}
                    {(idx === 0) && <td className={style.numeric} style={mismatch ? {color: "red", fontWeight: "800"} : {}} rowSpan={courseIds.length}>
                      {creditXero.toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    </td>}
                  </tr>
                );
              })}
            </>);
          })}
        </table>
      </div>
    );
  }
);
