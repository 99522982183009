import React from 'react';
import history from "../history/history";
import {Formik, Form, Field} from "formik";
import {NeatFormField} from "../controls/neatFormField";
import style from './userEditor.scss';
import {userApi} from "../api/userApi";
import {deskApi} from "../api/deskApi";
import {Button} from "../controls/button";
import {userSession} from "../api/userSession";
import {TeacherSelector} from "../courses/teacherSelector";
import {notificationApi} from "../api/notificationApi";
import {logError} from "../errors/errorConsole";
import homeStyle from "../main/home.scss";
import {Menu} from "../menu/menu";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {NeatFieldValue} from "../controls/neatFieldValue";
import moment from "moment";
import {RelationshipField} from './relationField';
import {ArrayField} from "../controls/arrayField";
import {Spinner} from "../controls/spinner";
import {shortUrlApi} from '../api/shortUrlApi';

export class UserEditor extends React.Component {
  constructor(props) {
    super(props);
    if (props.match.params.userId === 'new') {
      this.state = {
        user: {},
      }
    } else {
      if (props.location.state && props.location.state.user) {
        this.state = {
          user: props.location.state.user
        };
      } else {
        try {
          this.state = {};
          this.loadState();
        } catch (e) {
          logError(`Cannot load user ${props?.match?.params?.userId} from server`, e);
          history.push('/courses');
        }
      }
    }
    this.submit = this.submit.bind(this);
    this.resetPwd = this.resetPwd.bind(this);
    this.inviteToStudentApp = this.inviteToStudentApp.bind(this);
    this.inviteToAppStore = this.inviteToAppStore.bind(this);
    this.changePermissions = this.changePermissions.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
  }

  async loadState() {
    const userId = this.props.match.params.userId;
    let appToken;
    try {
      appToken = (await notificationApi.getStudentNotificationsTokens(userId))[0];
    } catch (e) {}
    this.setState({
      user: (await userApi.searchUsersByIds([userId]))[0],
      studentsNotificationsToken: appToken
    });
  }

  render() {
    const user = this.state.user || {};
    const appToken = this.state.studentsNotificationsToken;
    const ninjaIsAdmin = userSession.getUserGroups().includes("ADMINS");
    const userIsStudent = !!(user.groups && user.groups.includes("STUDENTS"));
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.rightSidePanel}>
          <div className={style.userEditor}>
            {user._id ?
              <div className={style.summary}>
                <div className={style.name}>{user.name}</div>
                <div className={style.field}>User since:
                  <span className={style.value}> {user.creationTime ? moment(user.creationTime).format("DD-MMM-YYYY") : "?"}</span>
                </div>
                <div className={style.field}>Used the app:
                  <span className={style.value}> {appToken ? moment(appToken.creationTime).format("DD-MMM-YYYY") : "never"}</span>
                </div>
              </div> :
              "Create new user"
            }
            <div className={style.creationInstrs}>{!user._id && "MAKE SURE THE USER DOESN'T EXIST ALREADY!! If it does, the user will not be created (you won't see any errors, the page will just do nothing). If it doesn't exist here, MAKE SURE THE USER DOESN'T EXIST IN ZOHO DESK EITHER. If it exists in zoho desk, MAKE SURE YOU ENTER THE SAME E-MAIL ADDRESS as shown in zoho desk. If the user in zoho desk doesn't have an email address, FIND OUT THE EMAIL ADDRESS FIRST, ADD IT IN ZOHO DESK, then create the user here."}</div>

            <Formik
              initialValues={{
                preferredLanguage: "id",
                ...user
              }}
              onSubmit={this.submit}
              enableReinitialize
            >
              {({submitForm}) => (
                <Form>
                  <Field type="text" name="name">
                    {({field}) =>
                      <NeatFormField {...field} label="Name"/>}
                  </Field>
                  <Field type="email" name="email">
                    {({field}) =>
                      <NeatFormField {...field} label="Email"/>}
                  </Field>
                  <Field type="phone" name="phone">
                    {({field}) =>
                      <NeatFormField {...field} label="Phone"/>}
                  </Field>
                  <Field type="number" name="yearOfBirth">
                    {({field}) =>
                      <NeatFormField {...field} label="Year of birth"/>}
                  </Field>
                  {ninjaIsAdmin ?
                    <Field type="text" name="country">
                      {({field}) =>
                      <NeatFormField {...field} label="Country"/>}
                    </Field> :
                    <NeatFieldValue label="Country" value={user.country}/>
                  }
                  {user.campaign ? 
                    <NeatFieldValue label="Campaign" value={user.campaign}/> :
                    <Field type="text" name="campaign">
                      {({field}) =>
                        <NeatFormField {...field} label="Campaign"/>}
                    </Field>
                  }
                  {(user.wbraid || user.gclid) ?
                    <NeatFieldValue label="Gclid" value={user.gclid || "-"}/> :
                    <Field type="text" name="gclid">
                      {({field}) =>
                        <NeatFormField {...field} label="Gclid"/>}
                    </Field>
                  }
                  {(user.wbraid || user.gclid) ?
                    <NeatFieldValue label="Wbraid" value={user.wbraid || "-"}/> :
                    <Field type="text" name="wbraid">
                      {({field}) =>
                      <NeatFormField {...field} label="Wbraid"/>}
                    </Field>                   
                  }
                  {(user.fbc) ?
                    <NeatFieldValue label="Fbc" value={user.fbc || "-"}/> :
                    <Field type="text" name="fbc">
                      {({field}) =>
                      <NeatFormField {...field} label="Fbc"/>}
                    </Field>
                  }
                  {(user.fbp) ?
                    <NeatFieldValue label="Fpb" value={user.fbp || "-"}/> :
                    <Field type="text" name="fbp">
                      {({field}) =>
                      <NeatFormField {...field} label="Fbp"/>}
                    </Field>
                  }
                  <NeatFormField name="preferredLanguage"
                                 label="Preferred language"
                                 component="select">
                    <option value="id">Indonesian</option>
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                    <option value="ms">Malay</option>
                    <option value="zh-tw">Chinese (traditional)</option>
                  </NeatFormField>
                  <ArrayField name="relationships"
                              label="Relationships"
                              innerComp={RelationshipField}
                              defaultValue={{type: "childOf", userId: null}}/>
                  <Button onClick={!this.state.isSubmitting && submitForm} disabled={this.state.isSubmitting}>
                    {this.state.isSubmitting ? <Spinner/> : "Submit"}
                  </Button>
                </Form>
              )}
            </Formik>

            <Button text="Create referral link" onClick={() => this.createReferralLink()}/>
            <div style={{paddingTop: 10}}/>

            <Button text="Create Google Play link to student app" onClick={() => this.inviteToStudentApp()}/>
            <div style={{paddingTop: 10}}/>

            <Button text="Create App Store link to student app" onClick={() => this.inviteToAppStore()}/>

            {this.state.link && <div className={style.link}>
              {this.state.link}
            </div>}

            {user._id && ninjaIsAdmin &&
              <>
              <div style={{marginTop: 80}}>User permissions</div>
              <Formik
                initialValues={{teacherId: user.teacherId, isStudent: userIsStudent}}
                onSubmit={this.changePermissions}
                enableReinitialize
              >
                {({submitForm}) => (
                  <Form>
                    <NeatFormField name="teacherId"
                                   label="Teacher"
                                   component={TeacherSelector}/>

                    <NeatFormField name="isStudent"
                                   label="Student"
                                   component="select">
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </NeatFormField>

                    <Button text="Change permissions" onClick={submitForm}/>
                  </Form>
                )}
              </Formik>
              <Button text="Reset pwd" onClick={this.resetPwd}/>
              <div style={{marginTop: 20}}>Send message</div>
              <Formik
                initialValues={{text: ""}}
                onSubmit={this.sendNotification}
                enableReinitialize
              >
                {({submitForm}) => (
                  <Form>
                    <Field type="text" name="text">
                      {({field}) =>
                        <NeatFormField {...field} label="Message"/>}
                    </Field>
                    <Button text="Send notification" onClick={submitForm}/>
                  </Form>
                )}
              </Formik>
              </>
            }

          </div>
        </div>
      </div>
    );
  }

  async submit(user) {
    this.setState({isSubmitting: true});
    user.email && user.email.trim && user.email.trim();
    user.phone && user.phone.trim && user.phone.trim();
    user.name && user.name.trim && user.name.trim();
    user.type = "student"; //this is dropped from the payload, it just signals user-service that it has to create a desk user
    try {
      if (!user._id) {
        const deskUser = await deskApi.searchDeskUserByEmail(user.email);
        if (deskUser) {
          user.deskUserId = deskUser.id;
        }
        await userApi.createUser(user);
      } else {
        await userApi.updateUser(user);
      }
      history.push('/courses');
    } catch (e) {
      logError("Failed to submit user", e);
    }
    this.setState({isSubmitting: false});
  }

  async resetPwd() {
    await userApi.resetPwd(this.state.user._id);
  }

  async createReferralLink() {
    const {shortUrl} = await shortUrlApi.createShortUrl(`https://www.cerah.co/cerahOnline/?referral_of=${this.state.user._id}&utm_source=referral`);
    this.setState({link: shortUrl});
  }

  async inviteToStudentApp() {
    const {user} = this.state;
    await userApi.updateStudentPermissions(user._id, {isStudent: true});
    const refreshToken = await userApi.createRefreshTokenForUser({userId: this.state.user._id});
    const link = `https://play.google.com/store/apps/details?id=co.cerah.students&referrer=${encodeURIComponent(`tk=${refreshToken}`)}`;
    this.setState({link});
  }

  async inviteToAppStore() {
    const {user} = this.state;
    await userApi.updateStudentPermissions(user._id, {isStudent: true});
    const link = `https://apps.apple.com/app/id1504514618#?platform=iphone`;
    this.setState({link});
  }

  async changePermissions(permissions) {
    const {user} = this.state;
    const userIsStudent = !!(user.groups && user.groups.includes("STUDENTS"));
    await userApi.updatePermissions(user._id, {
      isStudent: userIsStudent,
      teacherId: user.teacherId,
      ...permissions
    });
    this.loadState();
  }

  async sendNotification(notif) {
    if (this.state?.user?._id)
      await notificationApi.sendNotificationToStudent(this.state.user._id, notif);
  }
}
