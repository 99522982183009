// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baEEI0bP {\n  margin: 5px 10px;\n  max-width: 300px;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap; }\n  .baEEI0bP .B06YVmHf {\n    font-size: 13px;\n    color: green;\n    display: inline;\n    padding: 5px; }\n    .baEEI0bP .B06YVmHf.oqcZ_jk2 {\n      color: #8c8; }\n    .baEEI0bP .B06YVmHf.dFCV3EF_ {\n      border: 1px solid;\n      border-radius: 6px; }\n    .baEEI0bP .B06YVmHf.ZuNP4tY5 {\n      color: red; }\n      .baEEI0bP .B06YVmHf.ZuNP4tY5.oqcZ_jk2 {\n        color: #faa; }\n    .baEEI0bP .B06YVmHf.psUaKmiW {\n      color: #666; }\n      .baEEI0bP .B06YVmHf.psUaKmiW.oqcZ_jk2 {\n        color: #bbb; }\n\n.Mq40IzTu {\n  color: green; }\n\n.aEZgQhqL {\n  color: red; }\n", "",{"version":3,"sources":["webpack://./src/courseProposals/courseProposals.scss"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,eAAe,EAAA;EALjB;IAQI,eAAe;IACf,YAAY;IACZ,eAAe;IACf,YAAY,EAAA;IAXhB;MAcM,WAAW,EAAA;IAdjB;MAkBM,iBAAiB;MACjB,kBAAkB,EAAA;IAnBxB;MAuBM,UAAU,EAAA;MAvBhB;QA0BQ,WAAW,EAAA;IA1BnB;MA+BM,WAAW,EAAA;MA/BjB;QAkCQ,WAAW,EAAA;;AAMnB;EACE,YAAY,EAAA;;AAGd;EACE,UAAU,EAAA","sourcesContent":["@import '../theme.scss';\n\n.teachers {\n  margin: 5px 10px;\n  max-width: 300px;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n\n  .teacher {\n    font-size: 13px;\n    color: green;\n    display: inline;\n    padding: 5px;\n\n    &.notPicked {\n      color: #8c8;\n    }\n\n    &.picked {\n      border: 1px solid;\n      border-radius: 6px;\n    }\n\n    &.rejected {\n      color: red;\n\n      &.notPicked {\n        color: #faa;\n      }\n    }\n\n    &.responsePending {\n      color: #666;\n\n      &.notPicked {\n        color: #bbb;\n      }\n    }\n  }\n}\n\n.callSuccess {\n  color: green;\n}\n\n.callFailure {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teachers": "baEEI0bP",
	"teacher": "B06YVmHf",
	"notPicked": "oqcZ_jk2",
	"picked": "dFCV3EF_",
	"rejected": "ZuNP4tY5",
	"responsePending": "psUaKmiW",
	"callSuccess": "Mq40IzTu",
	"callFailure": "aEZgQhqL"
};
export default ___CSS_LOADER_EXPORT___;
